<template>
  <div>
    <v-radio-group
      class="ml-2"
      v-model="computedType"
      :column="false"
    >
      <v-radio
        :label="$t(`t.Scalar`)"
        value="scalar"
        class="mr-4"
      ></v-radio>
      <v-radio
        :label="$t(`t.Aggregators`)"
        value="agg"
      ></v-radio>
    </v-radio-group>
    <component
      :is="computedType"
      v-model="computedPeriod"
      :target-doc-ty="targetDocTy"
    />
  </div>
</template>

<script>
export default {
  components: {
    agg: () => import('./stats-period-agg'),
    scalar: () => import('./stats-period-scalar')
  },
  computed: {
    computedType: {
      get () {
        return this.dataPeriod?.type
      },
      set (type) {
        this.computedPeriod = Object.assign({}, this.computedPeriod, { type })
      }
    },
    computedPeriod: {
      get () { return this.dataPeriod },
      set (v) {
        this.dataPeriod = v
        this.dataDocumentEmit()
      }
    }
  },
  methods: {
    emitDocument () {
      if (!this.lodash.isEqual(this.value, this.dataPeriod)) {
        this.$emit('input', this.lodash.cloneDeep(this.dataPeriod))
      }
    }
  },
  data () {
    return {
      dataPeriod: {},
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  props: {
    value: Object,
    targetDocTy: String
  },
  watch: {
    value: {
      immediate: true,
      handler (v) {
        this.dataPeriod = v ? this.lodash.cloneDeep(v) : { type: 'scalar' }
        this.dataDocumentEmit()
      }
    }
  }
}
</script>
